import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import HomeContainer from 'components/Sections/Home/HomeContainer'
import HomeCarousel from 'components/Sections/Home/HomeCarousel'
// Models
import { IOptionalMetaProps, ISeoQuery } from 'models/SEO'
import { IArticleCard } from 'models/Article'
import { IInterviewCard } from 'models/Interview'
import { IDiskCard } from 'models/Disk'
import { ISliderItem } from 'models/Slider'
import { ISongCard } from 'models/Song'

type IndexPageProps = {
    articles: { nodes: IArticleCard[] }
    interviews: { nodes: IInterviewCard[] }
    discography: { nodes: IDiskCard[] }
    songs: { nodes: ISongCard[] }
    pageSeo: ISeoQuery
}

const IndexPage: React.FC<PageProps<IndexPageProps>> = ({ data }) => {
    const articles = data.articles.nodes
    const interviews = data.interviews.nodes
    const discography = data.discography.nodes
    const songs = data.songs.nodes
    const pageSeo = data.pageSeo
    const seo: IOptionalMetaProps = {
        title: pageSeo.title,
        description: pageSeo.description.text,
        image: pageSeo.image.file?.url,
    }

    return (
        <Layout seo={seo}>
            <HomeCarousel />
            <HomeContainer
                articles={articles}
                interviews={interviews}
                discography={discography}
                songs={songs}
            />
        </Layout>
    )
}

export default IndexPage

export const pageQuery = graphql`
    query IndexPageQuery {
        articles: allContentfulArticles(
            limit: 6
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                title
                slug
                date
                thumbnail {
                    gatsbyImageData(placeholder: BLURRED, width: 400)
                }
            }
        }

        interviews: allContentfulInterviews(
            limit: 6
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                title
                slug
                date
                thumbnail {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
            }
        }

        discography: allContentfulDisks(
            limit: 5
            sort: { fields: release_date, order: DESC }
        ) {
            nodes {
                name
                slug
                release_date
                cover {
                    gatsbyImageData(placeholder: BLURRED, width: 710)
                }
            }
        }

        songs: allContentfulSongs(
            limit: 8
            sort: { fields: release_date, order: DESC }
        ) {
            nodes {
                name
                slug
                release_date
                thumbnail {
                    gatsbyImageData(placeholder: BLURRED, width: 300)
                }
            }
        }

        pageSeo: contentfulSeo(slug: { eq: "home" }) {
            title
            description {
                text: description
            }
            image {
                gatsbyImageData
                file {
                    url
                }
            }
        }
    }
`
