import React from 'react'
import { Link } from 'gatsby'
import { getFormattedDate } from 'util/date'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Components
import FeaturedCard from 'components/UIElements/Card/FeaturedCard'
// Models
import { IDiskCard } from 'models/Disk'
// Styles
import * as styles from './HomeDisks.module.css'

type HomeDisksProps = {
    discography: IDiskCard[]
}

const HomeDisks: React.FC<HomeDisksProps> = ({ discography: disks }) => {
    if (disks.length === 0) return <></>
    const [lastDisk, ...discography] = disks

    return (
        <section className={styles.discography}>
            <header className={styles.discography_header}>
                <h2 className={styles.discography_title}>
                    Últimos Lanzamientos
                </h2>
                <Link
                    className={styles.discography_more_button}
                    to="/discography"
                >
                    Ver todo
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={['fas', 'chevron-right']}
                    />
                </Link>
            </header>
            <div className={styles.discography_content}>
                <article className={styles.last_disk}>
                    <FeaturedCard
                        title={lastDisk.name}
                        to={`/discography/${lastDisk.slug}`}
                        date={getFormattedDate(lastDisk.release_date)}
                        thumbnail={lastDisk.cover}
                    />
                </article>
                <div className={styles.all_disk}>
                    {discography.map((disk) => (
                        <article key={disk.slug} className={styles.small_card}>
                            <FeaturedCard
                                title={disk.name}
                                to={`/discography/${disk.slug}`}
                                date={getFormattedDate(disk.release_date, {
                                    short: true,
                                })}
                                thumbnail={disk.cover}
                            />
                        </article>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default HomeDisks
