import React from 'react'
// Components
import Content from 'components/Layout/Content'
import DefaultSidebar from 'components/Layout/Sidebar/DefaultSidebar'
import HomeFeatured from './HomeFeatured'
import HomeDisks from './HomeDisks'
import HomeSongs from './HomeSongs'
// Models
import { IArticleCard } from 'models/Article'
import { IInterviewCard } from 'models/Interview'
import { IDiskCard } from 'models/Disk'
import { ISongCard } from 'models/Song'
// Styles
import * as styles from './HomeContainer.module.css'

type HomeContainerProps = {
    interviews: IInterviewCard[]
    articles: IArticleCard[]
    discography: IDiskCard[]
    songs: ISongCard[]
}

const HomeContainer: React.FC<HomeContainerProps> = ({
    articles,
    interviews,
    discography,
    songs,
}) => {
    return (
        <div className={styles.home_page}>
            {/* <HomeSocial /> */}
            <Content>
                <HomeFeatured
                    items={articles}
                    title="Artículos recientes"
                    to="/articles"
                />
                <HomeDisks discography={discography} />
                <HomeFeatured
                    items={interviews}
                    title="Entrevistas"
                    to="/interviews"
                />
                <HomeSongs songs={songs} />
            </Content>
            <DefaultSidebar />
        </div>
    )
}

export default HomeContainer
