import React from 'react'
import { Link } from 'gatsby'
// Styles
import * as styles from './index.module.css'

type ButtonProps = {
    text: string
    to?: string
    onClick?: () => void
    className?: string
}

const Button: React.FC<ButtonProps> = ({ text, to, onClick, className }) => {
    const buttonClassName = className
        ? `${styles.button} ${className}`
        : styles.button

    if (to) {
        return (
            <Link className={buttonClassName} to={to}>
                {text}
            </Link>
        )
    }
    if (onClick) {
        return (
            <span className={buttonClassName} onClick={onClick}>
                {text}
            </span>
        )
    }
    return <span className={buttonClassName}>{text}</span>
}

export default Button
