import React from 'react'
import { getFormattedDate } from 'util/date'
// Components
import PageTitle from 'components/UIElements/PageTitle'
import FeaturedCard from 'components/UIElements/Card/FeaturedCard'
import Button from 'components/UIElements/Button'
// Models
import { IArticleCard } from 'models/Article'
import { IInterviewCard } from 'models/Interview'
// Styles
import * as styles from './HomeFeatured.module.css'

type HomeFeaturedProps = {
    title: string
    items: IArticleCard[] | IInterviewCard[]
    to: string
}

const HomeFeatured: React.FC<HomeFeaturedProps> = ({ items, title, to }) => {
    return (
        <section>
            <PageTitle title={title} />
            <div className={styles.items_content}>
                {items.map((item: IArticleCard | IInterviewCard) => {
                    const { title, date, thumbnail, slug } = item
                    const created_date = getFormattedDate(date)
                    return (
                        <article key={slug} className={styles.home_item}>
                            <FeaturedCard
                                title={title}
                                date={created_date}
                                thumbnail={thumbnail}
                                to={`${to}/${slug}`}
                            />
                        </article>
                    )
                })}
            </div>
            <Button text="Ver todos" to={to} />
        </section>
    )
}

export default HomeFeatured
