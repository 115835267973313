import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getFormattedDate } from 'util/date'
// Components
import FeaturedCard from 'components/UIElements/Card/FeaturedCard'
// Models
import { ISongCard } from 'models/Song'
// Styles
import 'swiper/css'
import 'swiper/css/pagination'
import * as styles from './HomeSongs.module.css'

type HomeSongsProps = {
    songs: ISongCard[]
}

const HomeSongs: React.FC<HomeSongsProps> = ({ songs }) => {
    return (
        <section className={styles.songs}>
            <header className={styles.songs_header}>
                <h2 className={styles.songs_title}>Últimás canciones</h2>
                <Link className={styles.songs_more_button} to="/songs">
                    Ver todo
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={['fas', 'chevron-right']}
                    />
                </Link>
            </header>
            <Swiper
                spaceBetween={16}
                slidesPerView={1}
                modules={[Autoplay, Pagination]}
                breakpoints={{
                    576: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                }}
                loop={true}
                speed={1000}
                autoplay={{
                    delay: 8000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                }}
                pagination={{ type: 'bullets', clickable: true }}
            >
                {songs.map((song) => {
                    const { slug, name, thumbnail, release_date } = song
                    const date = getFormattedDate(release_date)
                    return (
                        <SwiperSlide
                            key={slug}
                            className={styles.carousel_slide}
                        >
                            <FeaturedCard
                                to={`/songs/${slug}`}
                                title={name}
                                thumbnail={thumbnail}
                                date={date}
                            />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </section>
    )
}

export default HomeSongs
