import React, { useRef } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// Models
import { ISliderItem, ISlider } from 'models/Slider'
// Styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import * as styles from './HomeCarousel.module.css'

const HomeCarousel: React.FC = () => {
    const slider = (useStaticQuery(query) as ISlider).slider.nodes
    const prevRef = useRef<HTMLDivElement>(null)
    const nextRef = useRef<HTMLDivElement>(null)

    const onBeforeInit = (Swiper: SwiperCore): void => {
        if (typeof Swiper.params.navigation !== 'boolean') {
            const navigation = Swiper.params.navigation!
            navigation.prevEl = prevRef.current
            navigation.nextEl = nextRef.current
        }
    }

    return (
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            className={styles.carousel}
            modules={[Autoplay, Navigation, Pagination]}
            loop={true}
            speed={1000}
            autoplay={{
                delay: 8000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
            }}
            pagination={{ type: 'progressbar' }}
            onBeforeInit={onBeforeInit}
        >
            <div className={styles.carousel_arrow_left} ref={prevRef}>
                <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            </div>
            <div className={styles.carousel_arrow_right} ref={nextRef}>
                <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </div>
            {slider.map((slide) => (
                <SwiperSlide key={slide.url}>
                    <Link to={slide.url} className={styles.carousel_slide}>
                        <GatsbyImage
                            className={styles.carousel_slide_image}
                            image={slide.image.gatsbyImageData}
                            alt={slide.title}
                        />
                        <h1 className={styles.carousel_slide_title}>
                            {slide.title}
                        </h1>
                    </Link>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default HomeCarousel

const query = graphql`
    query {
        slider: allContentfulSliders(
            limit: 6
            sort: { fields: order, order: ASC }
        ) {
            nodes {
                title
                url
                image {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
    }
`
